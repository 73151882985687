import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react';  
const PTAMember = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'PTA Member - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>PTA Member</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>PTA Member</h1>
                    </div>
                </div>
                 
                <div className="row">
                  <div className="col-lg-12">
                    <h5> PRESENTATION DELHI </h5>
                   <ul className="list">
                       <li>Sr. Chitra Varghese</li>
                       <li>Sr. Regi Joseph</li>
                       <li>Sr. Rosamma Thomas</li>
                       <li>Sr. Maria Theresa Viegas</li>
                       <li>Sr. Jayasri Vethanayagam</li>
                   </ul>
                   </div>
                 </div>
                   <div className="row">
                      <div className="col-lg-12">
                    <h5> REPRESENTATIVES OF TEACHERS </h5>
                   <ul className="list">
                       <li>Ms. Chaturya K</li>
                       <li>Mrs. Meenakshi Chaturvedi</li>
                       <li>Mrs. Jyoti Sachdev</li>
                       <li>Mrs. Sherin George</li>
                   </ul>
                   </div>
                   </div>
                   <div className="row">
                      <div className="col-lg-12">
                    <h5> REPRESENTATIVES OF PARENTS </h5>
                   <ul className="list">
                       <li>Dr. Arun Sharma</li>
                       <li>Mr. Lalit Kumar</li>
                       <li>Mr. Samarendra Kumar</li>
                       <li>Mrs. Soji Thomas</li>
                       <li>Mr. Alok Kumar Sinha</li>
                       <li>Mrs. Supriya Banerjee</li>
                       <li>Mrs. Stephanie P. Lopchan</li>
                       <li>Mrs. Daania Farooqui</li>
                       <li>Mrs. Priyanka Malik</li>
                       <li>Mrs. Ashima Aggarwal</li>
                   </ul>
                   </div>
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default PTAMember
