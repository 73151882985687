import React, { useEffect, useState } from 'react'
import {getBirthdays} from'../Service/Api'
import LazyLoad from 'react-lazyload';
const Birthday = () => {
     const [data,setData] = useState([]);
     const [loading,setLoading] = useState(true);
     const [error,setError] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      useEffect(() => {
          const fetchData = async () =>{
                 try{
                    const birthdayData = await getBirthdays();
                    setData(birthdayData) ;  
                    console.log(birthdayData)
                 }catch(error){
                  setError(error);
                 }finally{
                  setLoading(false)
                 }
          }
          fetchData();
      },[]);
  
      const emptyArray = [
         {name:"Student Name",class:"Class",date:"DD MM"},
         {name:"Student Name",class:"Class",date:"DD MM"},
         {name:"Student Name",class:"Class",date:"DD MM"}
  
      ]
  return (
    <>
    <marquee direction="up" onMouseOver={(event) => event.currentTarget.stop()}  onMouseOut={(event) => event.currentTarget.start()}>
    {data && data.length > 0 ? (data.map((item,index) => {  
        const date = new Date();
           const day = date.getDate(); 
           const month = date.getMonth() ;  
           const mymonth = monthNames[month];  

         return (

          <div className="birthdaybox" key={index}>
          <div className="birthdayboximg">
              <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid"/>  </LazyLoad>
          </div>
          <div className="birthdayboxdesc">
               <h5>{item.name}</h5>
               <p>Class: {item.class} <span>{day} {mymonth} </span></p>
          </div>
  </div> 
         );
          })
          ) : (emptyArray.map((item,index) => (
      <div className="birthdaybox" key={index}>
              <div className="birthdayboximg">
              <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/defaultImage.jpg" className="img-fluid"/>  </LazyLoad>
              </div>
              <div className="birthdayboxdesc">
              <h5>{item.name}</h5>
              <p>Class:  {item.class} <span>{item.date}</span></p>
              </div>
      </div> 
     )))}
         

        {/*  <div className="birthdaybox">
        <div className="birthdayboximg">
            <img src="https://webapi.entab.info/api/image/PCSD/public/Images/birthdayimg1.png" className="img-fluid"/>  
        </div>
        <div className="birthdayboxdesc">
             <h5>Namita Patel</h5>
             <p>XI - B <span>14 June</span></p>
        </div>
       </div> */}
    </marquee>
    </>
  )
}

export default Birthday
