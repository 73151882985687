import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react';  
const QualityManagement = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Quality Management - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>Quality Management</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Quality Management</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12"> 
                <h5>Quality Policy</h5> 
                <p>We at Presentation Convent Senior Secondary School, Delhi, commit ourselves to adopting a Quality Management System to achieve and sustain excellence in imparting holistic education to our students as per our Presentation Vision, meeting and going beyond the curriculum prescribed by Central Board of Secondary Education, so that they may become worthy citizens of our motherland India, and an asset to humanity.</p>    
                <h5>Our Philosophy</h5>
                <ul>
                <li>Upholding the sacredness and inter-dependency of humanity, the earth and all life forms.</li>
                <li>Searching for alternative patterns of relationship between human beings (within and outside the boundaries of our motherland, irrespective of differences of class, caste or creed) the earth and the cosmos.</li>
                <li>Fostering our rich and spiritual heritage for peaceful co-existence.</li>
                <li>Facilitating a process where our students are enabled to discover the Light within themselves and grow to their full potential.</li>
                <li>Empowering our students to play their unique role in the transformation of society and the creation of a New World order.</li>
                </ul>    
                 <h5>Our Aim &amp; Dream</h5>   
                  <p><strong>Grooming of the young to become efficient, diligent, successful and empowered women.</strong> <br/>
                The Quality Management System (QMS), as per ISO 9001:2008 is an accepted management tool, which is helping us to achieve our goals by:</p>  
                <ul>
                <li>Focusing all efforts towards meeting the requirements for Quality in every endeavour.</li>
                <li>Ensuring better Management by integrating all activities that are instrumental in achieving the set goals.</li>
                <li>Providing purposeful direction and understanding of individual roles and their inter-relationship.</li>
                <li>Evolving well co-ordinated and well-knitted procedures that guide the institution to perform its best.</li>
                <li>Establishing a system that ensures a periodical assessment with implementation of corrective measures, if required.</li>
                    </ul>    
                    
                   </div>
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default QualityManagement
