import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import {getGallery} from'../Service/Api'
import LazyLoad from 'react-lazyload';
const Gallery = () => { 
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);

   useEffect(() => {
       const fetchData = async () =>{
              try{
                 const galleryData = await getGallery();
                 setData(galleryData) ;  
              }catch(error){
               setError(error);
              }finally{
               setLoading(false)
              }
       }
       fetchData();
   },[]);

   const emptyArray = [
      { title:"Gallery Title"},
      { title:"Gallery Title"},
      { title:"Gallery Title"},
      { title:"Gallery Title"}

   ]
  var settings = { 
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow:1,
            slidesToScroll: 1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
     <> 
     
     <Slider {...settings}>

     {data && data.length > 0 ? (data.map((item,index) => ( 
      <div className="item" key={index}>
        <div className="galleryimg">
          <div className="galleryimgbox">
          <LazyLoad>   <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" alt="PCS Delhi"/></LazyLoad>
                <div className="gallerydesc"> 
                    <h6>{item.title}</h6> 
                </div>
            </div>
          </div>
        </div>
        ))) : (emptyArray.map((item,index) => (
          <div className="item" key={index}>
        <div className="galleryimg">
          <div className="galleryimgbox">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/PCSD/public/Images/gal1.jpg" className="img-fluid" alt="PCS Delhi"/></LazyLoad>
                <div className="gallerydesc"> 
                    <h6>{item.title}</h6> 
                </div>
            </div>
          </div>
        </div>
        )))}
         
                 {/*
                       <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://webapi.entab.info/api/image/PCSD/public/Images/gal2.jpg" className="img-fluid" alt="PCS Delhi"/>
                <div className="gallerydesc">
                    
                    <h6>Sports Day</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://webapi.entab.info/api/image/PCSD/public/Images/gal3.jpg" className="img-fluid" alt="PCS Delhi"/>
                <div className="gallerydesc">
                     
                <h6>Sports Day</h6> 
                </div>
            </div>
          </div>
        </div>
        <div className="item">
        <div className="galleryimg">
          <div className="galleryimgbox">
              <img src="https://webapi.entab.info/api/image/PCSD/public/Images/gal4.jpg" className="img-fluid" alt="PCS Delhi"/>
                <div className="gallerydesc">
                   
                <h6>Sports Day</h6> 
                </div>
            </div>
          </div>
        </div>
                */}
       </Slider>
     
     </>
  )
}

export default Gallery