import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react';
import LazyLoad from 'react-lazyload';
const Uniform = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'School Uniform - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission </li>
                <li>School Uniform</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Uniform</h1>
                    </div>
                </div>
                <div className="row uniform">
                     <div className="col-lg-12">
                         <h5> UNIFORM CODE: </h5>
                          <p>No student will be allowed to enter the school premises without proper uniform.</p>
                        <ul>
                          <li> House uniform should be worn only on Wednesday.</li>
                          <li> Summer uniform w. e. f. New Academic Session (April).</li>
                          <li> Winter uniform w. e. f. 1st November.</li>
                          <li> School ID card is the most important part of the school uniform and it must be worn
                          every day.</li>
                          <li> Only students with short hair will wear a head band.</li>
                        </ul>
                     </div> 
                     <div className="col-lg-12">
                     <h5>Classes KG</h5>
                      
                     <LazyLoad><img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Uniform-KG.jpg" className="img-fluid uniformimg"/></LazyLoad>
                      <p> <strong>Summer Uniform:</strong> Red Check Tunic, White short, sleeves shirt, White Socks with three red & blue stripes, Black velcro shoes, Red head band/hair tie, plain hair clips. </p>
                     <p><strong>Winter Uniform:</strong> Bottle Green Trouser, White long sleeves, Shirt, Red Blazer with school monogram, Red scarf, red cardigan with school monogram, Black velcro shoes, Green socks, Red head band/ hair tie, plain hair clips.</p>
                   </div>  
                   
                 <div className="clr"></div>
                   <div className="col-lg-12">
                     <h5>Classes I-II</h5>
                     
                     <LazyLoad><img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Uniform-I-II.jpg" className="img-fluid uniformimg"/></LazyLoad>
                       <p><strong>Summer Uniform:</strong> Red check Tunic, White short sleeves shirt, White Socks with three red & blue stripes, Black velcro shoes, Red head band/hair tie, plain hair clips.</p>
                     <p><strong>Winter Uniform:</strong> Bottle green trouser, White long sleeves shirt,  Red Blazer with school monogram, Red scarf, red cardigan with school monogram, Black velcro shoes, dark green socks, Red head band/hair tie, plain hair clips.</p>
                     <p><strong>Sports Uniform:</strong> White shorts, House T-shirt with school monogram,  White sock with three red & blue stripes, White velcro shoes, White head band/ hair tie & white plain hair clips.</p>
                   </div>
                 <div className="clr"></div>
                   <div className="col-lg-12 uniform">
                     <h5>Classes III - VIII</h5>
                   
                        <LazyLoad><img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Uniform-III-VIII.jpg" className="img-fluid uniformimg"/></LazyLoad>
                     <p><strong>Summer Uniform:</strong> Red check skirt, White short sleeves shirt, White Socks with three red & blue stripes, Black velcro shoes, Red head band/hair tie, plain hair clips.</p>
                     <p><strong>Winter Uniform:</strong> Bottle green trouser, White long sleeves shirt,  Red Blazer with school monogram, Red Muffler, red cardigan with school monogram, Black velcro shoes, dark green socks, Red head band/hair tie, plain hair clips.</p>
                     <p><strong>Sports Uniform:</strong> White divided skirt,  House T-shirt with school
                 monogram,  White socks with three red & blue stripes, White velcro shoes, White head band/hair tie, white plain hair clips.</p>
                     <p><strong>Karate Uniform:</strong> Karate Dress, Black velcro shoes & Socks.</p>
                   </div>
                 <div className="clr"></div>
                   <div className="col-lg-12">
                     <h5>Classes IX - XII</h5>
                   
                        <LazyLoad><img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Uniform-IX-XII.jpg" className="img-fluid uniformimg"/></LazyLoad>
                    <p> <strong>Summer Uniform:</strong> Red check kameez with half beige coat, Beige salwar,  White socks with three red & blue stripes, Black velcro Shoes, Red plain hair clips, Red head band/ hair tie.</p>
                     <p><strong>Winter Uniform:</strong> Red check kameez with half beige coat, Beige salwar, Red Blazer with school monogram, Red Cardigan with school monogram, Red Muffler, White Woolen Socks, Black velcro Shoes, Red plain hair clips, Red head band/ hair tie.</p>
                     <p><strong>Sports Uniform:</strong> White divided Skirt, House T-shirt with school
                 monogram, White socks with three red & blue stripes, White Velcro Shoes, White head and/hair tie, plain hair clips</p>
                   </div>
                  
                </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Uniform
