import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import LazyLoad from 'react-lazyload';
import {  useEffect } from 'react';
const SchoolMottoObjective = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'School Motto &   Objective - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>School Motto &   Objective</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Motto &  Objective</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12"> 
                <h5>School Motto </h5>    
                 <p className="inner-para">The motto of the school is <strong>' “VIRTUE” and "LABOUR” '</strong>. The students are expected to strive for these ideals and to practice justice, charity, honesty, truthfulness, generosity, loyalty and gratitude. They must work hard in school and at home and realise the dignity of labour.</p>   
                <h5>Our Objective </h5>    
                <div className="clearfix">
                <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/objective.jpg" className="img-fluid col-md-6 float-md-end mb-3 objm" alt=""/></LazyLoad>
               <ul> 
                              <li>To provide qualitative teaching-learning process.</li>
                               <li>To create a just society.</li>
                               <li>To promote human values and character formation.</li>
                               <li>To work towards empowerment of girls.</li>
                               <li>To work towards environmental protection and conservation.</li>
                              </ul>    
                 </div>
                </div> 
                </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolMottoObjective
