import { useEffect, useState } from 'react';
import { getPrincipalMessage } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Message = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const emptyData = [
    {
      id: 1,
      category: "Designation",
      message: "Is a Christian Minority School. It is an unaided English medium school for girls. The school is run by the Society of Presentation of the Blessed Virgin Mary (PBVM) Order under Societies Registration Act, XXI of 1860,(Punjab Amendment) Act 1957 as extended to the Union Territory of Delhi. The school is located in the heart of Delhi.",
      name: "Name",
      images: "https://webapi.entab.info/api/image/PCSD/public/Images/principal.jpg",
    },
  ];

  return (
    <>
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <div className="homemsgbox" key={index}>
            <div className="homemsgboxtop">
              <div className="homemsgboximg">
           <LazyLoad>     <img
                  src={`https://webapi.entab.info/api/image/${item.attachments}`}
                  className="img-fluid"
                  alt="Pcs Delhi"
                />
                </LazyLoad>
              </div>
              <div className="homemsgboxname">
              <div dangerouslySetInnerHTML={{ __html: item.name }} />
              </div>
            </div>
            <div className="homemsgboxdesc">
              <div dangerouslySetInnerHTML={{ __html: item.message }} />
            </div>
          </div>
        ))
      ) : (
        emptyData.map((item, index) => (
          <div className="homemsgbox" key={index}>
            <div className="homemsgboxtop">
              <div className="homemsgboximg">
              <LazyLoad>  <img
                  src={item.images}
                  className="img-fluid"
                  alt="Pcs Delhi"
                />
                </LazyLoad>
              </div>
              <div className="homemsgboxname">
                <p>{item.name}</p>
              </div>
            </div>
            <div className="homemsgboxdesc">
              <div dangerouslySetInnerHTML={{ __html: item.message }} />
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default Message;
