import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react'; 
const Rules = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Rule and  Regulations - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>   Academic </li>
                <li> Rule and Regulations</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Rule and  Regulations</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <ul>
                      <li><strong>Speak in English</strong> at all times as it is the medium of instruction.</li>
                     <li>Procure prescribed books for the new session within a week of declaration of results.</li>
                     <li><strong>Read the school diary regularly</strong> to be well-informed about rules, discipline code, QMS, EMS etc.</li>
                     <li><strong>Bring the school diary regularly</strong> and make appropriate entries.</li>
                     <li>Not carry books, periodicals or any other material disapproved by the Principal.</li>
                     <li>Compensate damage to school property.</li>
                     <li><strong>Not scribble</strong> on walls and furniture.</li>
                     <li>Switch off lights and fans before leaving the classroom for assembly, P.T., computer class, library etc.</li>
                     <li><strong>Assemble on the upper – field</strong> immediately after keeping the school bag in the classroom (before the morning assembly bell).</li>
                     <li><strong>Not leave the classroom</strong> in the absence of any teacher.</li>
                     <li>Carry the class pass / medical pass or a written permission in the school diary when out of the class for some reason.</li>
                     <li>Keep the classroom and school premises clean, neat and tidy at all times.</li>
                     <li>Greet the Principal, teachers and people around.</li>
                     <li>Bring a leave note in the diary (leave record page) duly signed by parents in case of one/two days leave. For a long leave permission to be granted by the Principal. If not well for a long time, medical certificate to be submitted.</li>
                     <li>Get the diary signed by parents in case of a remark by Principal/Vice Principal/Headmistress or teacher.</li>
                     <li>Not leave the school premises without the permission of the Principal.</li>
                     <li>Make calls from the school reception with permission in case of illness or any emergency.</li>
                     <li>Not celebrate or hold any birthday party. (strictly prohibited)</li>
                     <li>Not collect any money or present any gift to any staff member/friend.</li>
                     <li><strong>Be in the classroom</strong> before meditation begins, after the break.</li>
                     <li>Co-operate with the class captain/student council members to ensure discipline in the school.</li>
                     <li><strong>Be on time</strong> to school or else bear the consequences.</li>
                     <li>Do the given home work regularly, keep the note books/ text books covered, neat and tidy. Address all correspondence to the Principal with full name, class and section.</li>
                     <li><strong>48 hour prior notice</strong> needed to apply for certificates.</li>
                     <li>Bring written permission from parents (to go back home on her own) in case of a stay back/ picnic and duly endorsed by the teacher.</li>
                     <li><strong>Carry the bus pass</strong> if using the school bus, maintain discipline while travelling and not put her hand/head outside the window or stand on the footboard.</li>
                     <li>Inform the bus captain, the day there is any change in the schedule. (for going home)</li>
                     <li>Inform lost or found property at the school reception.</li>
                         </ul>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Rules
