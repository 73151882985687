 import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Campuscare = () => {
  return (
     <>
         <div className="campuslogo">
              <Link to="https://www.pcscampuscare.org/" target="_blank">
                <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="PCS Delhi"/></LazyLoad></Link>
         </div>  
      </>
        
  )
}

export default Campuscare
 