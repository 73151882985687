import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
import {  useEffect } from 'react';
const SchoolPolicy = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'School Policy - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission </li>
                <li>School Policy</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Policy</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <p><strong>Presentation Convent Sen. Sec. School derive their policy of Education from the life</strong>, and love and compassion of Jesus Christ as revealed in the Gospel. In particular, it is derived from the life of dedication of Nano Nagle, the foundress of the Congregation. Education in the spirit of the Gospels and Nano Nagle encompasses the development of growing consciousness of the dignity and the rights of every person. This enables the person to live in fellowship. Our school strives to involve the entire school community in taking active responsibility in conducting and supporting outreach programmes. Our Concern is for all the people of God within the local community where the school is situated especially those of the Christian faith and those deprived in any way. Essential to Education is the development of knowledge and skills enabling a person to be a loyal citizen, peaceful and just in this world and thus establish the reign of God through his Love, Peace and Justice.</p>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SchoolPolicy
