import React, { useEffect } from 'react'; 
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
const TeacherMessage = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Teacher Message - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School </li>
                <li>Teacher Message</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Teacher Message</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-12">
                   
                  <h5>Say NO to Exam-O-Phobia <br/>
                  Say YES to Exam-O-Prep </h5>    
                  <p><strong>Here are trail-o-tips to make your examination journey a smooth ride.</strong></p>
                  <p className="inner-para">The motto of the school is <strong>' “VIRTUE” and "LABOUR” '</strong>. The students are expected to strive for these ideals and to practice justice, charity, honesty, truthfulness, generosity, loyalty and gratitude. They must work hard in school and at home and realise the dignity of labour.</p>   
                  <ul>
                   <li> Focus on your strong subjects to <strong>get optimal marks and set realistic goals</strong> for your not-sofavorite subjects.</li>
                   <li> Alternate easy and difficult topics of different subjects.</li>
                   <li> Write and revise, speak your answers before family member to revise orally too.</li>
                   <li> Every night before sleeping, jot down precise topics/ subjects you will cover next day.</li>
                   <li> Ward off external noise and distractions( use your earphone, unplugged of course)</li>
                   <li> Study at fixed place with table and upright chair with your study material properly organised.</li>
                   <li>  Best short breaks are stretch exercises, spot jogging for couple minutes, power nap (20 minutes) and meditation of 2-3 minutes.</li>
                   <li> Eat moderate and healthy with more focus on berries, dairy products, nuts and vegetables.</li>
                   <li> Keep a bottle of water on your study table and keep sipping off dehydration and fatigue.</li>
                   <li> Ask for any help or support you need from family members, don't assume they know.</li>
                   <li> Keep away from disagreements and arguments. Conflicts resolution can be postponed till exams are done.</li>
                   <li> Outings, parties, family functions etc to be put on hold till exams are done.</li>
                   <li> 2 effective Acupressure points to overcome fatigue and exam nervousness are: <strong>Third Eye Point:</strong> Gently press the point where eyebrows meet, for 1 minute and repeat 3 times.</li>
                   <li> Sun Point: gently press with both forefingers, the point just below where the eyebrows end on both sides of the head for 1 minute and repeat 3 times.</li>
                 </ul>  
         
                 <p><strong>Do your best and ask Almighty to make your task easy and smooth.</strong></p>
         
                 <h5>Happy Studying!!!</h5>
                 <p><strong>Dr Madhumati Singh (Counselor)</strong></p>
               </div>
             </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default TeacherMessage
