import React from'react' 
import { getVideo } from '../Service/Api';
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useState,useEffect } from 'react'; 
const VideoGallery = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Video Gallery - Presentation Convent School Delhi';  
      }, []);
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("All"); // Default to "All"
   const [yearRanges, setYearRanges] = useState([]);

   useEffect(() => {
       const fetchData = async () => {
           const galleryData = await getVideo();
           setData(galleryData);

           // Extract unique years
           const uniqueYears = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.date) {
                       const year = new Date(item.date).getFullYear(); // Extract only the year
                       console.log(year)
                       return [year];
                   } else {
                       return [];
                   }
               }))
           );

           // Remove the map function for generating year ranges
           const uniqueYearRanges = uniqueYears.map(year => {
               return `${year}`;
           });

           uniqueYearRanges.sort((a, b) => b - a);
           console.log("Unique Year Ranges:", uniqueYearRanges); // Log uniqueYearRanges
           setYearRanges(uniqueYearRanges);
       };
       fetchData();
   }, []);

   useEffect(() => {
       // Filter data based on selected year
       let filteredData = data;
       if (selectedYear !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.date) {
                   const year = new Date(item.date).getFullYear();
                   return parseInt(selectedYear) === year;
               } else {
                   return false;
               }
           });
       }
       setFilteredData(filteredData);
   }, [selectedYear, data]);


   const emptyData = [
    {title:"Title"},
    {title:"Title"},
    {title:"Title"}

]
  return (
      <>
      <Header/>
      <div className="innerslide"> 
          <ul className="breadcrumb">
              <li><Link to="/"> Home</Link> </li> 
              <li>  Gallery  </li>
              <li>Video Gallery</li>
          </ul>
     </div>
      <div className="innersec">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <h1>Video Gallery</h1>
                  </div>
              </div>
              <div className="row">
             <div className="col-lg-12">
             <div className="chooseyear">
             <select id="chooseddlYear"
                 value={selectedYear}
                 onChange={(e) => setSelectedYear(e.target.value)}
                 className="yearSelection"
             >
                 <option value={"All"}>All</option>
                 {yearRanges.map((yr) => (
                     <option key={yr} value={yr}>{yr}</option>
                 ))}
             </select>

         </div>
         <div className="clr"></div>
         <div className="row">
                {filterData && filterData.length > 0 ? (filterData.map((item, index) => (
                 <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch" key={index}>
                     <div className="video-gallery">
                         <Link to={item.videoUrl} target="_blank">
                             <i className="bi bi-play-circle-fill"></i> 
                             <h3>{item.title}</h3>
                             
                         </Link>
                     </div>
                 </div>
             ))):(emptyData.map((item, index) => ((
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch" key={index}>
                <div className="video-gallery">
                    <Link to="#" target="_blank">
                        <i className="bi bi-play-circle-fill"></i> 
                        <h3>{item.title}</h3> 
                    </Link>
                </div>
           </div>
             ))))}  
 
         </div>
               </div>
               </div>
                   
               </div>  
               </div> 
              <Footer/>
      </>
  )
}

export default VideoGallery
