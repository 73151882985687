import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
import { useEffect } from 'react'
const BusRoute = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Bus Route - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission  </li>
                <li>Bus Route</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container busroute">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Bus Route</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                       <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          
                            <p> P-03 (Re-Survey) </p>
                            </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p><strong>Shadipur Depot, (Up via):</strong> l/t Patel Road, l/t Pusa Road, l/t Faiz Road, straight Rani Jhansi Marg, Upto Ice factory, r/t Lala Hardev Sahai Marg, Upto ISBT, Straight Lothian Road, Straight Netaji Subhash Marg, Upto Red fort Red light &amp; Back to Netaji Subbhash Marg, L/t to school.</p>
                                <p> <strong>Down Via:-</strong> Presentation Convent Sr. Sec. School, l/t Subhash Marg, Straight Lothian Road, Upto ISBT, L/T Lala Hardev Sahai Marg,Upto Ice Factory, l/t Rani Jhansi Marg, Filimistan, Faiz Road, Straight Pusa Road, Upto East Patel Nagar circle Roundabout to Patel Nagar Road,upto Under Flyover Shadipur Depot, U Turn and Back to Shadipur Depot.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            <p> P-04 </p>
                            </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>G.T.K. Depot , r/t GT Road/Mall Road Extn. Marg, straight, Azadpur, l/t M.G. Road, l/t G/H Block, Model Town-III, r/t G/C Block, r/t F/K Block, Model Town-II, l/t M.G. Road, r/t Bhama Shah Marg, straight Gurudwara Nanak Piao, l/t Prem Nath Sharma Marg, round about Clock Tower, r/t Roshanara road, l/t Ice factory to r/t Lala Ram Dev Sahai Marg/Nitya Nand Marg, straight round about ISBT, l/t ISBT Terminal, to straight Mori Gate, Lothian Marg, GPO, r/t Kauria Bridge (S.P. Mukherji Marg) to school.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            <p> P-05 </p>
                            </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Shadipur Depot , l/t Patel Road, l/t on Pusa Road, l/t Guru Ravi Dass Marg, r/t towards Channa Market, l/t Saraswati Marg, ( on return journey: from Saraswati Marg to r/t Arya Samaj Road, l/t Ragarpura Road, to Radha Swami Satsang) D.B. Gupta Road, r/t New Delhi Railway Station to Connaught Place, r/t to Minto Road, r/t to D.D.U. Marg, l/t to Bal Bhawan, Straight G.B. Pant Hospital, Block-D, Type-II quarters to Mata Sundri Road, Straight Mir Hamdard Marg, r/t to Vivekanand Marg, r/t to J.L. Nehru Marg, l/t to Subhash Marg to school.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            <p> P-06 </p>
                            </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p><strong>Up via:</strong> Hasanpur Depot l/t Swami Daya Nand Marg up to under flyover K.K. Xing, r/t Bhartendu Harish Chander Marg up to Red light l/t Road no. 72 l/t Maharaja Suraj Mal Marg up to Jagatpuri Xing, r/t Swami Daya Nand Marg up to Keshav Chowk r/t G.T. road up to under flyover, Shahdara l/t Loni Road up to Durgapuri Xing, l/t Road no. 68 up to Maujpur Xing, l/t Asha Ram Tyagi Marg up to Seelampur under flyover r/t Eastern Approach road, Shastri Park l/t ISBT flyover via loop to M.G. Marg up to under flyover Yamuna Bazar r/t Dr. S.P. Mukherjee Marg to l/t Presentation Convent School.</p>
                                <p><strong>Down via:</strong> Presentation Convent School l/t Subhash Marg up to ISBT l/t U-turn from Lala Hardev Sahai Marg, straight Eastern Approach road up to under flyover Seelampur red light l/t Asha Ram Tyagi Marg up to Maujpur Xing r/t road no. 68 up to Durgapuri Xing r/t Loni road up to under flyover, Shahdara r/t G.T. Road up to Keshav Chowk l.t Swami Daya Nand Marg up to Jagatpuri Xing l/t Maharaja Suraj Mal Marg road straight r/t road no. 72, AGCR up to T-Point r/t Bhartendu Harish Chander Marg up to K.K. Xing l/t Swami Daya Nand Marg, Hasanpur village red light U-turn l/t Hasanpur Depot.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            <p> P-07 </p>
                            </button>
                      </h2>
                      <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot , Gagan Cinema, r/t Road no. 64, straight Anand Gram, r/t to Rajiv Gandhi Hospital, Tahirpur, Sunder Nagari, l/t from Gole Chakar, E-Block Nand Nagri/G.T.B. Enclave Pocket-C, Nand Nagri Terminal, straight GTB Xing, Road no. 68, straight C-Block Nand Nagri (100 foot Road), Durga Puri Chowk, Jyoti Colony, Shahdara, r/t T-Point Maujpur, straight Mangal Pandey Marg, C-Block Yamuna Vihar, Bhajanpura, l/t Pushta Road, Kartar Nagar, Usmanpur, r/t Shastri Park Xing, Shastri Park Metro Station, Yamuna Bridge, l/t ISBT Ring Road, through slip road, Yamuna Bazar, r/t under Flyover, Hanuman Setu on S.P. Mukherji Marg, to Presentation Convent School. (On return journey: The bus will go to Kauria Bridge, GPO, to ISBT flyover then follow morning route).</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                            <p> P-08 </p>
                            </button>
                      </h2>
                      <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot, l/t Wazirabad Road via slip road, U-turn under Nand Nagri Flyover and back Wazirabad Road up to Gagan Cinema, r/t Road no. 69, straight, r/t Road no. 68 up to Maujpur Chowk, r/t Asha Ram Tyagi Marg up to Yamuna Vihar, l/t Chittar Gupta Vatika to Yamuna Vihar, C-5, D.D.A. Market road, straight, l/t Yamuna Vihar Depot Road, l/t Shahid Bhagat Singh Park to Lal Bahadur Shastri Marg, straight, r/t Asha Ram Tyagi Marg up to Seelampur Metro Station, under flyover, r/t G.T. Road, straight, Eastern Approach Road to Via, I.S.B.T. slip road to Yamuna Bazar, under flyover, r/t Yamuna Bazar, straight, S.P. Mukherji Marg, to School. (In Down Direction: Bus will go to Presentation School, l/t Subhash Marg, Straight, G.P.O. r/t Hanuman Mandir Road, l/t Mahatma Gandhi Marg, straight, via loop to Eastern Approach Road and follow same morning route).</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                            <p> P-09 </p>
                            </button>
                      </h2>
                      <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot, l/t main Wazirabad road, slip road, B.R. Ambedkar College, l/t Asha Ram Tyagi Marg, r/t C-8/C-4, Yamuna Vihar (Bhagwan Parshu Ram Mandir Marg) l/t from Canara Bank r/t Connvey Associate, B-Block, Yamuna Vihar Rajkiya Vidyalaya r/t from My Fair l/t P.S. Bhajanpura r/t from P.S. Bhajanpura to l/t main Wazirabad road, l/t to A-Block, Shiv Hari Om Mandir (Pushta road) up to Shastri Park r/t (Eastern Approach road) Shyam Giri Mandir up to ISBT l/t Mahatma Gandhi Marg, Yamuna Bazar r/t to S.P. Mukherjee Marg to l/t school.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                            <p> P-10 GTK Depot (Re-Survey) </p>
                            </button>
                      </h2>
                      <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p><strong>UP Via:- </strong>GTK Depot R/T GT Karnal Road, /-\Azadpur Metro Station, Upto Mahendra Enclay &amp; Mera Bagh Red light L/T Stadium Road (Mahindra Enclay 1st picking point) Upto Red Light R/T Lala Achint Ram Marg Gujrawala Town &amp;Dara Wla B-Block Upto T- Point R/T Bhamha Shah Road Upto Gujrate Namken L/TGupta Colony Priyedharshani Vihar Road, L/T Kalyan Vihar Road, UP to R/T Sani Mandir Road, UP to Sani Mandir, L/T Apollo Road UP to T-Point L/T Police Line Apollo Road, UP to T-Point R/T Bhama Shah Road, UP to Metro Line Model Town Straight Model Town 1st Road, UP to Bhola Juice Corner R/T Togor Park Road, UP to Kathi No.261, L/T Mukherjee Nagar West Safa! Both No.521, L/T Om Mandir Marg, Dhaka Village, Redio Colony, Rosery School, R/T Avtar Marg, Sant Nirankari Colony Road, Sant Nirankari Satsang Bhawan, Avtar Park, Mukherjee Nagar Bhandh, Dhirpur Pusta Nala Side Road, R/T Banda Bahadur Marg, Batra Cinema Inder Vihar BBM Depot UP to GTB Nagar, Metro Station Red Light L/T MG Marg, Mall Road UP to P.S. Civil Line Red Light, R/T Rajpur Road, UP to Snatan Dharm Mandir &amp; STA, L/T Under Hill Road, Banshidhar Gupta Marg, UP to Civil Line Metro Station R/T Shamnath Marg, UP to L/T Lala Hardev Sahai Marg, UP to Under Flyover U-Turn &amp; Back, Lala Hardev Sahai Marg, ISBT Kashmiri Gate, L/T Lothin Road, UP to GPO Red Light R/T S.P Mukherjee Marg., L/T Presentation School. </p>
                                <p> <strong>DN Via:-</strong> Presentation School L/T Neta Ji Subhash Marg, Straight Lothian Road, UP to GPO R/T &amp; L/T M.G Marg, UP to ISBT OTC Stand MG Marg, L/T Yamuna Marg, R/T Samnath Marg, UP to Civil Line Metro Station Red Light L/T Lala Bansidhar Gupta Marg, Under Hill Road, UP to STA T-Point, R/T Rajpur Road, Sanatan Dharm Mandir UP to P.S Civil Line Red Light, L/T MG Marg, Mall Road, UP to GTB Nagar Metro Station Red Light R/T Banda Bahadur Marg, BBM Depot lnder Vihar Batra Cinema UP to Police Post, Nala Crossing, L/T Nala Side Road, Dhirpur L/T Nirankari Colony Road, Avtar Marg, UP to Satguru Nirankari Satsang Bhawan, L/T Om Mandir Marg, Rosery School Redio Colony, UP to Dhaka Village R/T Sagal Both No.521, Mukherjee Nagar West Road UP to Kothi No. 261 R/T Tagor Park Road, Malik Pur UP to Bhola Juice Corner L/T Model Town 1st Road, UP to Metro Line Straight, Bhama Shah Road, UP to Red Light L/T Jahanara Road, UP to R/T P.S Polo Road, R/T Polo Road, UP to Sani Mandir, L/T Kalyan Vihar Road, R/T Piryedharsani Vihar Road, UP to T-Point R/T Bhama Shah Road, L/T Lala Aachant Ram Road, Derawala Town UP to T-Point L/T Mahaveer Enclve Road, UP to R/T GT. Karna! Road, Azad Pur Metro Station, Azad Pur Main Sabzi Mandi, Sarai Pipal Thala, UP to L/T GTK Depot. </p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                            <p> P-11 </p>
                            </button>
                      </h2>
                      <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>G.T.K. Depot , l/t GT Road/Mall Road Extn. Marg, under flyover bypass, l/t to Dr. Hedgewar Marg, straight Burari Xing, l/t Sant Nagar/Burari Road, U-turn from DSIDC Wine shop/Radhey Shyam Tyagi Marg, T-point back same straight, Dhaka-Burari road, straight GTB Xing, straight Vijay Nagar, straight Patel Institute, r/t Chhatra Marg, straight Karori Mal College Hostel, Hans Raj College, Malkaganj, l/t side Road, Hans Raj College, Swami Jai Prakash Marg, l/t Sri Ram Institute, r/t Acharya Sushil Muni Marg/Pahari Road, straight MCD Offices to Lt. Governor Secretariat, Old Police Line Road, l/t Lala Hardev Sahai Marg, straight ISBT round about, ISBT, l/t ISBT Terminal to straight Mori Gate, Lothian Marg, GPO, r/t Kauria Bridge (S.P. Mukherjee Marg), l/t to school.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                            <p> P-12 </p>
                            </button>
                      </h2>
                      <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>G.T.K. Depot , r/t G.T. Karnal up to Azadpur on Ring Road, GTB Nagar, BB Marg Xing, Vishwa Vidalaya Metro Station, l/t Basheswer Nath Gupta Marg, Timarpur, D.J.B. CGHS Dispensary Timarpur, r//t to r/t on Ch. Fateh Chand Marg, l/t Ring Road, l/t Govind Dass Sahib Marg, Majnu ka Tila, r/t to Gurudwara Majnu ka Tila on ISBT Road, Kashmere Gate ISBT on Ring Road, r/t from Hanuman Mandir, Angoori Bagh to S.P. Mukherjee Marg, l/t to School.</p>
                                <p>In down direction, the bus will go from school, l/t Subhash Marg, r/t from G.P.O. l/t Ring Road, ISBT, then follow morning route.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEleven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                            <p> P-13 RHN-4 (Re-Survey) </p>
                            </button>
                      </h2>
                      <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p><strong>UP Via:-</strong> Rohini Depot-4 R/TBadly Bawana Road R/t Road No.B-7 Crime Branch Office Rohini Sector-18 (1st picking point) R/t Road No.B-4, Manav Chowk straight upto Sachdeva Public Schook Red light L/t Dr.K.N. Katju Marg uptoi Parshant Vihar Red light R/t Dr.Hedgewar Marg L/t Income Tax Colony to Dushehra Groiund road LU Block Pitampura L/t Maharaja Agarsain Marg up to GH Block Shalimar Bagh Redlight R/T Gopal Mandir Marg Straight L/T Sahid Udham Singh Marg ,Prabhu Dayal Public School R/T Bhagwan Mahavir Marg R/T mahatma Gandhi Marg upto Prern Bari Bridge L/T Maharaja Nahar Singh Marg upto Perna Chowk L/t Ch. Guiab Singh Marg R/t Major Dhayan Chand Stadium to Wazirpur JJ Colony Road, Nimri Colony, Bharat Nagar Police Station straight R/t Swami Narain Marg upto Shastri Nagar Metro Station Red light L/t Veer Vandu Vairagi Marg L/t Ch.Nand Lal Marg, P.S. Gulabi Bagh straight R/t Swami Narain Marg R/t Inder Chander Shastri Marg, Nagia Park straight upto P.S. Roop Nagar circle to Banglo Road upto Malka Ganj Chowk L/t Hans Raj College Road L/t T.V.Thadani Marg R/t Acharya Sushi! Muni Marg upto MCD office Civil Lines red light R/t Raj Pur Road L/t Tara Chand Mathur Marg straight Lala Hardev Sahai Marg upto ISBT flyover U turn and back Lala Hardev Sahai Marg L/t Lothian Marg to GPO Red light Straight Netaji Subhash Marg up to Redfort Redlight U Turn &amp; back Netaji Subhash Marg L/T Presentation school </p>
                                <p><strong>DN Via:-</strong> Presentation School L/t Netaji Subhash Marg Straight Lothian Road up to ISBT L/T Lala Hardev Sahai marg R/T to L/T Tara Chand Mathur Marg up to Aruna Assaf Ali Hospital Redlight R/T Rajpur Road up to MCD Office Redlight L/T Acharya Sushi! Muni Marg L/T Mahatma Hans Raj Marg up to Malka Ganj Chock R/T Prof.N.D.Kapoor Marg L/T Banglo Road up to P.S.Roop Nagar Circle L/T Inder Chander Shastri Marg Straight up To Choki No-2 Redlight L/T Swami Narain Marg L/T Ch.Nand Lal Marg .P.S. Gulabi Bagh Straight R/T Veer Banda Bairagi Marg up to Shastri Nagar Redlight R/T Swami Narain Marg up to Bharat Nagar Xing L/T Baba Sahib Bhim Rao Ambedkar Marg ,P.S.bharat Nagar ,Nimri Colony up to Major Dhyan Chand Stadium Redlight L/T Ch.Guiab Singh Marg up to Prerna Chock R/T Maharaja Nahar Singh Marg up to Prembari Bridge R/T Mahatma Gandhi Marg up to Shalimar Bagh Xing L/T Bhagwan Mahavir Marg Straight L/T Sahid Udmam Singh Marg ,Prabhu Dayal Public School R/T Jhule Lal Mandir Marg up to GH Block Shalimar Bagh L/T Maharaja Aggarsain Marg up to District Park Redlight R/T RamLila Ground Road up to Income Tax Colony Redlight L/T Dr.Hedgewar Marg U Turn &amp; Back Dr.Hedgewar Marg up to Prashant Vihar Redlight L/T Dr.Hedgewar Marg up to Sachdeva Public School Sector-13 Rohini R/T Road No-B-4 up to Rohini Sector-18 Metro Station Redlight L/T Road No-B-7 ,Crime Branch Office Straight L/T Badly Bawana Road L/T Rohini Depot-4. </p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwl">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwl" aria-expanded="true" aria-controls="collapseTwl">
                            <p> P-14 NND (Re-Survey </p>
                            </button>
                      </h2>
                      <div id="collapseTwl" className="accordion-collapse collapse" aria-labelledby="headingTwl" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p><strong>Via UP:-</strong> Nand Nagari Depot L/T Wazirabad, Then Under Flyover U-Turn &amp; Back Wazirabad Road, Upto Gagan Cinema Red Light Upto Sunder Nagari Round About To Road No. 69, (Rajeev Ganshi Institute 1st Pickinh Point) Upto Tahir Pur Red Light L/T Road No. 64, Upto DLF Red Light R/T Seemapuri Depo Road, Upto Seema Puri Depot Red Light R/T Dilshad colony Road, F-block Dilshan colony Upto Aggarwal Sweet Straight J &amp; K Side Road, Pammi Sweet Straight H-Pocket Lovely Public School L/T Gurudwara Side Road, Upto Red Light R/T G.T. Road, Upper Flyover Shahdra Shyam Lal College, Seelam Pur L/T Old River Bridge Road, Under Railway Line L/T Pusta Road, Geeta Colony Shamshan Ghat Upto Geeta colony Flyover Loop R/T Raja  Ram Kohli Marg, Upto Shant van Straight Nishad Raj Marg, Upto T-Point R/T Netaji Subhas Marg, Lal Kila Upto L/T Presentation School. </p>
                                <p><strong>Via Down:-</strong> Presentation School L/T Netaji Subhas Marg, R/T S.P.Mukherjee Marg, Yamuna Bazar Straight By Pass Road, Upto Geeta colony Flyover L/T Rajaram Kohli Marg, L/T Via Loop Pusta Road, Gandhi Nagar, Bus stop Kailash Nagar, Upto Under Railway Line R/T Old River Bridge Road, Upto, Seema Puri Metra Station Red Light L/T G.T. Road, Welcome Shyam lal COllege Straight upper Flyover Shahdra Upto Majid L/T G.T.B Road No. 64, Delhi Jal Board Road, R/T BSES Offioce B- Block Road, Govt. School Pocket - A Dilshad Garden Modern School Upto R/T Park Side Road, Upto Pammi Sweet L/T  J &amp; K Road, Aggarwal Sweet Dilshad Garden M-Block Straight Dilshad Colony Road, F-Block Upto Old Seema Puri Cluster Bus Depot Red Light L/T Old Seema Puri Depot Road, Upto DLF Red - T Block No. 64, Upto Tahir Pur Red Light R/T Road no. 69, Rajeev Gandhi Institute Last Droping Point, Nagari Circle Gagan Cinema Red Light L/T Wazirabad Road, Bank Colony L/T Nand Nagari Depot.</p>
                                <p><strong>Up Kms. 19.6<br/>
                                  Down Kms 19.0<br/>
                                  Ext. Dead Kms. 10.0<br/>
                                  Total Kms. 48.6</strong></p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThir">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThir" aria-expanded="true" aria-controls="collapseThir">
                            <p> P-15 </p>
                            </button>
                      </h2>
                      <div id="collapseThir" className="accordion-collapse collapse" aria-labelledby="headingThir" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot , l/t Mangal Pandey Marg, B-Block Yamuna Vihar, l/t Bhajan pura police Colony, round about Park Road, Thana Bhajan pura, l/t Mangal Pandey Marg, l/t to Khajoori Chowk to Pushta Road, r/t G.T. Road, l/t from Flyover, I.S.B.T. to Ring Road, r/t Yamuna Bazar Road, S.P. Mukherji Marg to School.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFort">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFort" aria-expanded="true" aria-controls="collapseFort">
                            <p> P-16 RID-I (New Bus) </p>
                            </button>
                      </h2>
                      <div id="collapseFort" className="accordion-collapse collapse" aria-labelledby="headingFort" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p><strong>UP Via:-</strong> Rajghat Depot-I L/T Ring Road Straight L/T Geeta Colony Flyover UP to T-Point L/T Geeta Colony Road, Pusta Road Straight Shamshan Ghat Bus Stop UP to Gandhi Nagar Red Light Straight Old G.T. Road Straight Shastri Park Metro Red Line Road, UP to T-Point Red Light R/T Estran Aproch Road,( Old G.T. Road) Straight Seelam Pur Bus Stop Side Flyover Road, Straight Hallan Chowk Red Light L/T Asharam Tyagi Marg, Straight Cooler Market, UP to )afrabad Metro Station Straight Veshno Mata Mandir Moaz Pur Chowk Red Light ( 1st Picking Point ) Straight L/T Sagwan Park Dawar C-Block Yamuna Vihar Straight Shri Sanatan Dharm Mandir Straight Sarvodaya Kanya Vidhalayai UP to T-Point L/T &amp; R/T B-Block Late Vijay Vahal Marg, UP to T-Point, R/T Police Station Bhajan Pura, Opposite R/T Tanki Road, UP to T-Point L/T Bhajan Pura Road, Straight Bhajan Pura Bus Stop Straight Side Flyover Road, UP to Khajuri Circle L/T Pusta Road, UP to Mihir Bhcj Chowk Straight UP to Shastri Park Chowk Red Light R/T Estran Aproch Road, UP to Straight L/T M.G. Road UP to Nigam Bodh Ghat, Straight R/T Under Flyover S.P. Mukherjee Marg, Straight UP to L/T Presentation School.</p>
                                <p><strong>DN Via:- </strong>Presentation School L/T Lothian Road Straight GPO Bus Stop Xing to R/T UP to L/T Mahatma Gandhi Marg, Straight UP to L/T Shahdra Upper Flyover Straight Estran Aproch Road, UP to Shastri Park Red Light L/T Pusta Road UP to Khajuri Circle Round About Bhajan Pura Road, UP to Gokal Puri Flyover Side Road, UP to Gokal Puri Red Light Under Flyover R/T U-Trun &amp; Back Bhajan Pura Road, UP to Bhajan Pura Red Light L/T Aggarwal Standerd Sweet Tanki Road, Straight T-Point Police Station Bhajan Pura L/T &amp; L/T B-Block Road, UP to T-Point L/T &amp; R/T C-Block Road, Straight UP to T-Point, Red Light Sagwan Park Dwar R/T Asharam Tyagi Marg, UP to Veshno Mata Mandir Maoz Pur Chowk, Straight )afrabad Metro Station, Cooler Market, UP to Shastri Park Red Light, R/T Estran Aproch Road, Straight UP to Ajeet Nagar Bus Stop L/T Old G.T. Road Straight Shastri Park Metro Red Line Road, UP to Kailash Nagar Chowk Red Light, Straight Pusta Road UP to Rajghat Flyover Pusta Road, UP to Raja Ram Kohli Marg, Straight L/T Via I.T.O. Road, UP to U-Trun &amp; Back ISBT Road, Straight L/T Via Rajghat Depot-I. </p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFift">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFift" aria-expanded="true" aria-controls="collapseFift">
                            <p> P-17 </p>
                            </button>
                      </h2>
                      <div id="collapseFift" className="accordion-collapse collapse" aria-labelledby="headingFift" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot , l/t Mangal Pandey Marg, l/t Loni Road, Durga Puri Chowk, r/t G.T. Road, l/t Swami Dayanand Marg, r/t from Aggarwal Sweets, Krishna Nagar, r/t Lal Quarters Road, A-Block Krishna Nagar to Main Road Jheel Khurenja, r/t Department of Social Welfare to Geeta Colony, r/t Subhash Road, l/t Old Yamuna Bridge towards Yamuna Bazar Road, S.P. Mukherji Marg, l/t to Presentation Convent School.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSixt">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixt" aria-expanded="true" aria-controls="collapseSixt">
                            <p> P-18 </p>
                            </button>
                      </h2>
                      <div id="collapseSixt" className="accordion-collapse collapse" aria-labelledby="headingSixt" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Hasanpur Depot , l/t Swami Dayanand Marg, l/t Vikas Marg, straight to Madhuban Chowk, Shakarpur, Laxmi Nagar, r/t Pushta Road straight towards Old Yamuna Bridge, Yamuna Bazar, Dr. S.P. Mukherji Marg, l/t to Presentation Convent School.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSevent">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSevent" aria-expanded="true" aria-controls="collapseSevent">
                            <p> P-19 </p>
                            </button>
                      </h2>
                      <div id="collapseSevent" className="accordion-collapse collapse" aria-labelledby="headingSevent" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot, straight on Depot road up to H-block, Dilshad Garden, l/t towards G.T. Road, straight on G.T. Road, r/t towards A&amp;B Blocks, Dilshad Garden as route no. 280 up to G.T.B. Hospital Xing, l/t to Mental Hospital, Swami Dayanand Hospital, r/t on G.T. Road, Shahdara, Sham Lal College, straight up to Old Yamuna Bridge, Xing, Ring Road, towards Yamuna Bazar, l/t for Kauria Bridge, school.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEighte">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighte" aria-expanded="true" aria-controls="collapseEighte">
                            <p> P-20 </p>
                            </button>
                      </h2>
                      <div id="collapseEighte" className="accordion-collapse collapse" aria-labelledby="headingEighte" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Hasanpur Depot, r/t Swami Daya Nand Marg, l/t Road no. 56, l/t Swami Daya Nand Marg, Hasanpur Depot, straight slip road, Karkari crossing, l/t Vikas Marg, straight, Madhuban Chowk, Shakarpur, Laxmi Nagar main market, Lovely Public School, Priyadarshini Vihar l/t from State Bank of India, Khureji Khas to Patparganj Road, l/t from Police Station, Geeta Colony towards Work Centre Road, l/t to Pushta Road, r/t Vikas Marg, r/t Ring Road, l/t to Railway colony, Yamuna Bazar to school.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNinet">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNinet" aria-expanded="true" aria-controls="collapseNinet">
                            <p> P-21 </p>
                            </button>
                      </h2>
                      <div id="collapseNinet" className="accordion-collapse collapse" aria-labelledby="headingNinet" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Hasanpur Depot , r/t to Swami Daya Nand Marg, r/t from Shani Mandir to National Victor School, r/t Bhatia Apartments, l/t Mother Dairy Road, l/t from D.V.B. sub-station to l/t National Highway no. 24, r/t Mayur Vihar Phase II, l/t Bus Terminal, r/t Kalyanvas road, r/t to Chand Cinema, l/t 13-Block, Trilokpuri, r/t towards Mayur Vihar Phase I, l/t to r/t Pocket-I, C.G.H.S. Dispensary, l/t to l/t Patparganj, l/t Pandav Nagar road, l/t Vikas Marg, r/t Bahadur Shah Zafar Marg, Subhash Marg to Presentation Convent School.</p>
                             </div>
                      </div>
                    </div>
                           <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwen" aria-expanded="true" aria-controls="collapseTwen">
                            <p> P-22 </p>
                            </button>
                      </h2>
                      <div id="collapseTwen" className="accordion-collapse collapse" aria-labelledby="headingTwen" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                                <p>Nand Nagri Depot, r/t on Wazirabad Road, r/t to Gagan Cinema, r/t Road no. 68, G.T.B. Hospital road, l/t G.T. road, r/t Outer Ring road, Surya Nagar, l/t from Ramaprastha Xing towards Check-Post, Vivek Vihar, straight to Vivekanand College, E.S.I. Hospital, Thana Vivek Vihar, DDA flats, r/t towards College of Applied Sciences for Women, l/t on Swami Amar Dev Marg, (Kasturba Nagar) l/t at Ambedkar Park, r/t on main road, Vishwas Nagar towards Mandir Marg, Krishna Nagar, Lal Quarters road, Jheel, Geeta Colony, Works Centre Road, l/t Pushta Road, r/t Vikas Marg, r/t Ring Road, Yamuna Bazar, Dr. S.P. Mukherji Marg to school.</p>
                             </div>
                      </div>
                    </div>
                  </div>
                   
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default BusRoute
