import React, { useEffect } from 'react';
import { Link ,NavLink,useLocation, } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Header = () => {
  const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  return (
      <>
        <div className="mainheader">
        <div className="headertop">
        <div className="topheaderleft">
             <div className="topheaderleftbg">
                <LazyLoad> <img src="https://webapi.entab.info/api/image/PCSD/public/Images/headerleft.png" className="shape"/></LazyLoad>
                   <div className="topheaderdesc">
                  <LazyLoad> <img src="https://webapi.entab.info/api/image/PCSD/public/Images/phone.gif" className="img-fluid"/></LazyLoad>
                   <p><Link to="#">011-23862862</Link> <span><Link to="#">mail@pcsdelhi.in</Link></span>
                   </p>
                  <LazyLoad> <img src="https://webapi.entab.info/api/image/PCSD/public/Images/mail.gif" className="img-fluid"/></LazyLoad>
                   <Link to="#">mail@pcsdelhi.in </Link>
                   </div>
             </div>
          </div>
           <div className="headertopright highlight-tab"> 
           <p className="d-flex space-even highlight-blk">
               <a href="#" target="_blank"><LazyLoad><img src='https://webapi.entab.info/api/image/PCSD/public/Images/twitter.png'></img></LazyLoad>
               </a><LazyLoad><img src='https://webapi.entab.info/api/image/PCSD/public/Images/line.png'></img></LazyLoad>
               <a href="#" target="_blank"><LazyLoad><img src='https://webapi.entab.info/api/image/PCSD/public/Images/insta.png'></img></LazyLoad></a><LazyLoad>
                <img src='https://webapi.entab.info/api/image/PCSD/public/Images/line.png'></img></LazyLoad>
               <a href="#" target="_blank"><LazyLoad><img src='https://webapi.entab.info/api/image/PCSD/public/Images/facebook.png'></img></LazyLoad></a><LazyLoad>
                <img src='https://webapi.entab.info/api/image/PCSD/public/Images/line.png'></img></LazyLoad>
               <a href="#" target="_blank"><LazyLoad><img src='https://webapi.entab.info/api/image/PCSD/public/Images/linkdin.png'></img></LazyLoad>
               </a>
           </p> 
           </div>
      </div>
      <div className="headermid">
           <Link to="/"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/logo.png" className="img-fluid"/> </Link>
      </div>
      <div className="header-nav">
      <nav className="navbar navbar-expand-lg">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
              <span className="navbar-toggler-icon"></span>
          </button>                       
          <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
              <li className="nav-item active">  <NavLink className="nav-link" to="/"><LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/home.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad> </NavLink> </li>
               <li className="nav-item dropdown">
                <div className="nav-link"  data-bs-toggle="dropdown">  School <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad> </div>
                    <ul className="dropdown-menu fade-up">
                    <li><NavLink className="dropdown-item" to="/SchoolInformation">School Information </NavLink></li>
                    <li><NavLink className="dropdown-item" to="/TeacherMessage">Teacher's Message</NavLink></li>
                     <li><NavLink className="dropdown-item" to="/StaffInformation">Staff Information</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SchoolHistory">School History </NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SchoolPrincipals">Our Principals</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/CelestialMentor"> Celestial Mentors</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/EchosofWisdom"> Echoes of Wisdom </NavLink></li>
                    <li><NavLink className="dropdown-item" to="/CatalystofGrowth">Catalysts of Growth</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SchoolCabinetMembers">Cabinet Members</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SchoolMottoObjective">School Motto &amp; Objective</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/QualityManagement">Quality Management</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/EnvironmentalManagement">Environmental Management</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/PTAMember">PTA Members</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SMCMembers">SMC Members</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Vacancy">Vacancy</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/CBSEMandatory">CBSE Mandatory Disclosure</NavLink></li>
</ul>  
              </li> 
              <li className="nav-item dropdown">
                 <div className="nav-link" data-bs-toggle="dropdown">   Admission  <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad></div>
                    <ul className="dropdown-menu fade-up">
                    <li><NavLink className="dropdown-item" to="/AdmissionDetails">Admission Details</NavLink></li>
                    {/* <li><NavLink className="dropdown-item" to="/SchoolFees">School Fees</NavLink></li> 
                    <li><NavLink className="dropdown-item" to="/BusRoute">Bus Routes</NavLink></li> */}
                    <li><NavLink className="dropdown-item" to="/Uniform">School Uniform</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SchoolPolicy">School Policy</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Infrastructure">Infrastructure </NavLink></li> 
                    <li><NavLink className="dropdown-item" to="/ChildProtection">Child's Protection &  Safe Guarding</NavLink></li>
</ul>  
              </li>
              <li className="nav-item dropdown"> 
              <div className="nav-link"  data-bs-toggle="dropdown">  Academic <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="i</LazyLoad>mg-fluid"/> </LazyLoad> </div>
                <ul className="dropdown-menu fade-up">
                  <li><NavLink className="dropdown-item" to="/Rules">Rules And Regulations</NavLink></li>  
                  <li><NavLink className="dropdown-item" to="/CbseResultAnalysis">CBSE Result Analysis</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/PrimarySchool">Primary School</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/SeniorMiddleSchool">Senior Middle School</NavLink></li>
                  <li><NavLink className="dropdown-item" to="/StudySkills">Effective Study Skills</NavLink></li>
                  <li><NavLink className="dropdown-item" to="https://www.pcscampuscare.org/" target="_blank">Syllabus</NavLink></li>
                  <li><NavLink className="dropdown-item" to="https://www.pcscampuscare.org/" target="_blank">Holiday Homework</NavLink></li>  
                  <li><NavLink className="dropdown-item" to="/QuestionPapers">Question Papers</NavLink></li>         
                  <li><NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/PCSD/public/pdf/booklist.pdf" target="_blank">Booklist</NavLink></li>    
                  <li><NavLink className="dropdown-item" to="https://wakelet.com/@presentationconvent692" target="_blank">E-Library</NavLink></li>    

                      
                </ul> 
              </li>
              <li className="nav-item dropdown">
                <div className="nav-link"  data-bs-toggle="dropdown"> Activities <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad></div>
                    <ul className="dropdown-menu fade-up">
                    <li> <NavLink className="dropdown-item" to="/Sports">Sports</NavLink></li>
                    <li> <NavLink className="dropdown-item" to="/Clubs">Clubs</NavLink></li>
                    <li> <NavLink className="dropdown-item" to="/InterSchoolCompetitions">Inter School Competitions</NavLink></li>
                    <li> <NavLink className="dropdown-item" to="/Cultural">Cultural</NavLink></li>
                    <li> <NavLink className="dropdown-item" to="/SocialActivities">Social Activities</NavLink></li>
                    <li> <NavLink className="dropdown-item" to="/SDG">SDG</NavLink></li>
               </ul>   
              </li>  
              <li className="nav-item dropdown">
              <div className="nav-link"  data-bs-toggle="dropdown"> Gallery <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad></div>
                   <ul className="dropdown-menu fade-up">
                      <li><NavLink className="dropdown-item" to="/PhotoGallery">Photo Gallery</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/VideoGallery">Video Gallery</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/Magazine">School Magazine </NavLink></li>
                      <li><NavLink className="dropdown-item" to="/EHandbook">E-Handbook</NavLink></li>
                      <li><NavLink className="dropdown-item" to="/MediaGallery">Media Gallery</NavLink></li>
                 </ul>   
            </li> 
            <li className="nav-item dropdown">
            <div className="nav-link"  data-bs-toggle="dropdown"> Alumnae <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad></div>
                 <ul className="dropdown-menu fade-up">
                 <li><NavLink className="dropdown-item" to="/OfficeBearers">Office Bearers</NavLink></li>
                 <li><NavLink className="dropdown-item" to="/AlumnaeEvents">Alumnae Events</NavLink></li>
                 <li><NavLink className="dropdown-item" to="https://www.pcscampuscare.org/" target="_blank">Online Registration</NavLink></li>
</ul>   
          </li> 
          <li className="nav-item dropdown">
           <div className="nav-link"  data-bs-toggle="dropdown"> Quick Links<LazyLoad> <img src="https://webapi.entab.info/api/image/PCSD/public/Images/navarrow.png" alt="PCS Delhi" className="img-fluid"/></LazyLoad></div>
                <ul className="dropdown-menu fade-up">
                <li><NavLink className="dropdown-item" to="/Tc">TC Issued</NavLink></li>
                <li><NavLink className="dropdown-item" to="/ParentGuidline">Portal (Help?)</NavLink></li>
                <li><NavLink className="dropdown-item" to="/ErpSoftware">ERP Software</NavLink></li>
                {/* <li><NavLink className="dropdown-item" to="/Vacancy">Vacancy </NavLink></li> */}
                  </ul>   
           </li> 
          </ul>
          </div> 
     </nav>
  </div>
        </div>
      </>
  )
}

export default Header
