import React, { useEffect } from "react";
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import LazyLoad from 'react-lazyload';
const Infrastructure = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Infrastructure - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Admission </li>
                <li>Infrastructure</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container infra">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Infrastructure</h1>
                    </div>
                </div>
               
<h5>School Assembly</h5>
<div class='row'>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>
</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>
</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>
</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_4.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/assembly_4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>
</div>
</div>

<h5>Art Class Room </h5>
<div class='row'>
<div className="col-xl-6 col-lg-6 col-md-12">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/art_room_0.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/art_room_0.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/art_room_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/art_room_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/art_room_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/art_room_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>

</div>
<h5>Chemistry Lab</h5>
<div class='row'>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/chem_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/chem_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/chem_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/chem_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/chem_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/chem_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/chem_4.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/chem_4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div> 
</div>

<h5>Computer Lab</h5>
<div className="row">
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/comp_lab_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
</div>


<h5>School Building</h5>
<div class='row'>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_4.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/school_building_4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div> 
</div>

<h5>Baskteball Playground</h5>
<div className="row">
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_4.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/basketball_4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
</div>

<h5>Children's Playzone</h5>
<div className="row">
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_4.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/kids_park_4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
</div>

<h5>School Library</h5>
<div className="row">
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/library_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/library_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/library_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/library_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/library_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/library_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/library_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/library_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
</div>

<h5>School Physics Lab</h5>
<div className="row">
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/physics_lab_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
</div>

<h5>Paper Recycle Unit</h5>
<div className="row">
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_1.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_1.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_2.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_2.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_3.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_3.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
<div className="col-xl-3 col-lg-6 col-md-6">
<LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_4.jpg"><img src="https://webapi.entab.info/api/image/PCSD/public/Images/paper_recycle_unit_4.jpg" className=" img-fluid" alt="PCS DELHI" /></a> </LazyLoad>

</div>
</div>

            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Infrastructure
