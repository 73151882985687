import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
import LazyLoad from 'react-lazyload';
const SocialActivities = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Social Activities - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Activity </li>
                <li>Social Activities</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Social Activities</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <LazyLoad><img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Social-1.png" className="img-fluid sportimg leftimg"/></LazyLoad>
                      <p> <span className="green">Disaster Management: </span>The need to create awareness, educate and develop skills of children to respond to emergencies has perhaps never been more felt than in the aftermath of recent disasters. Advance planning, effective implementation, strategies and development of right attitude for safety, co-ordination and co-operation with like-minded brains working in this field are important for ensuring the safety in schools. The memories of Kumbakonam tragedy where 90 innocent school going children were burnt to death in a school fire are still fresh in our minds. The best tribute we can offer is to wake up to our collective responsibilities as adults by training our student and staff and equipping them with right knowledge and practice to ensure that nothing like this ever happens again. </p>
                      
                      <p> Our school realizing the importance of Disaster Management conducts mock drills regularly so that the students and teachers can meet and face disasters as and when they strike. Evacuation plans are displayed at strategic points on each floor. Teams have been formed for evacuation, fire fighting and informing the concerned government authorities. </p>
                      <p> <span className="green">National Institute of Open Schooling: </span> <span className="green">Outreach:</span> </p>
                      <LazyLoad><img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Social-2.png" className="img-fluid sportimg rightimg"/></LazyLoad>
                      <p>The vision of Nano Nagle, foundress of Presentation Order, was to educate the poor girls. In continuing with her vision and commitment to social cause, we, at Presentation Convent School Senior Secondary school started conducting classes for these underprivileged girls in mid 1980s. In 1992, the school was formally accredited with Open School (as National Institute of Open Schooling was then called). Since then the school has successfully catered to thousands of girls from economically weaker sections of society. We have a group of committed teachers and regular classes are held at Secondary and Senior Secondary level. The results have shown outstanding performances and the feedback from the students has been inspiring.  </p>
                      
                      <p> Social work is an integral part of the school curriculum, to effectively involve students, staff and parents in making education socially relevant. Our Outreach programme enables to create awareness on social issues and sensitize the school community to contribute their little bit for the underprivileged. The programme includes:</p>
                      
                      <ul>
                      <li><strong>VISITS TO WELFARE ORGANISATIONS</strong> like Missionaries of Charity (Karkardooma) Ozanam Home (Home for the aged) Shanti Avaslana Cancer Patient,Institute of Physically Challenged,etc.</li>
                      <li><strong>INTERACTION WITH THE UNDERPRIVILEGED SECTIONS OF SOCIETY: </strong>This programme helps to sensitize the students towards the plight and misery of less fortunate and the students are motivated to contribute to their welfare.</li>
                      <li><strong>CELEBRATING IMPORTANT OCCASIONS</strong> like 'Human Rights Day', 'Literacy Day', Women's Day, etc. where special assemblies are conducted by enacting street plays, group discussions, interviewing eminent personalities, etc.</li>
                      </ul>
                      <p><span className="green">Inclusive Education:</span> Inclusive education is becoming an integral part of our school curriculum. The school emphasizes in making space for the students who are slow learners and physically challenged. An attempt is made to provide a congenial environment for the child to cope up with her disability and help her to perform her level best. </p>
                      
                      <p><span className="green">Yoga &amp; Meditation:</span> Yoga and Meditation is a regular feature in our school. Vipassana meditation is practised every day by the entire school, which in turn, helps us to be more focused and disciplined in our work. Regular Yoga exercises for students help them to maintain a healthy body and mind.</p>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SocialActivities
