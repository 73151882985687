import { useEffect } from "react";
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import LazyLoad from 'react-lazyload';
const PrimarySchool = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Primary School - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>   Academic </li>
                <li>Primary School</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container infra">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Primary School</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <div className="clearfix">
                      <LazyLoad>  <img src="https://webapi.entab.info/api/image/PCSD/public/Images/primary-school.jpg" className="img-fluid col-md-4 float-md-end mb-3" alt=""/> </LazyLoad>
                      <p>When the child first enters the school, she is provided with a friendly environment, which makes it easier for her to adjust to school. The focus of the teacher is to give personal attention to each child in order to make learning an enjoyable experience. This makes it easier for the child to place her trust in the teacher who, in turn, can then provide a more holistic and creative approach in moulding the young minds for the future.</p>
                      <p>The textbooks are carefully chosen and the curriculum is systematically prepared keeping in mind the all round development, inherent skills and mental ability of each child so as to enable her to understand the concept and develop the necessary skills needed for future life.</p>
                      <p>Every lesson is imparted with a personalized touch. While the advanced teaching methods are used to impart knowledge, on a more humane level, there is an all out effort to understand the child's psyche. A major part of this approach involves motivating the students in the last stretch of preparations and making them believe that they have the potential to do well in all fields.</p>
                      <p>Attention is given to the health and nutrition of the children to ensure that they have the physical energy and natural attention span needed for learning. Nutritional and medical supplements are provided to under nourished children from low income families. Exercise and games are encouraged to build strength and stamina.</p>
                      <p>The existing classrooms are converted into technology enabled smart classrooms equipped with interactive digiboard system. It is connected to a PC that is networked to a dedicated smart class knowledge centre in the campus.</p>
                      <p>The school authorities believe that smart class teaching is more effective as the students get to watch visuals of what they are taught. The Principal feels that: <strong>"It is more effective and an enjoyable experience for the students. It also helps the teachers acquire new skills as well as help the students improve their knowledge."</strong></p>
                      <p>The methodology of teaching lays emphasis on Activity Based Learning. It is a method where subjects are taught through activities. The purpose is not to let the young child feel stressed. This makes learning an interesting experience. It also helps the child to understand and retain the matter taught. Care is also taken that a child is not burdened with too many assignments/homework.</p>
                      <p>There are no written/formal examinations and the evaluation is continuous and is based on the year's performance. The child is given grades and not marks, which is in keeping with the directives issued by CBSE.</p>
                      <p>Remedial teaching is an integral part of school curriculum which enables the weak students to improve in their respective subjects. Firstly, the child is identified on the basis of her performance in class. Then, the teacher puts in her efforts to encourage and bring out the best in the child so that she is able to cope up and be at par with her classmates.</p> 
                      </div> 
                 </div>
            </div>  
        </div> 
        </div>
       <Footer/>
     </>
  )
}

export default PrimarySchool
