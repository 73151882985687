import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {getNews} from'../Service/Api'
const News = () => {
     const [data,setData] = useState([]);
     const [loading,setLoading] = useState(true);
     const [error,setError] = useState(null);


      useEffect(() =>{
            const fetcData = async () =>{
                    try{
                         const newsData = await getNews();
                         setData(newsData);
                         console.log(newsData);
                    }catch(error){
                        setError(error);
                    }finally{
                        setLoading(false);
                    }
            }
            fetcData();
      },[])
      const emptyData = [
        {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" },
        {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" },
        {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" }

    ]
  return (
     <>
     <div className="newsbg">
         <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up"  onMouseOut={(event) => event.currentTarget.start()}>
             <ul className="newsboxlist">
             { data.length > 0 ? (data.map((item,index) =>(
                <li key={index}>
                         <div className="newsbox">
                             <div className="newsdesc">
                                 <h6>{item.title}</h6>
                                 <p>{item.description}</p>
                             </div>
                             <Link to={`https://webapi.entab.info/api/image/${item[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                         </div>
                      </li>
         
           ))): (emptyData.map((item,index) => (
            <li key={index}>
            <div className="newsbox">
                <div className="newsdesc">
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                 </div>
                 <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
            </div>
            </li>
           ))) }



                 {  /*  <li>
                   <div className="newsbox">
                       <div className="newsdesc">
                           <h6>Class XI Stream allotment 2024</h6>
                           <p>Class XI Stream allotment 2024</p>
                        </div>
                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                   </div>
                   </li>
                   <li>
                   <div className="newsbox">
                       <div className="newsdesc">
                           <h6>Class information verification marks 2023 -24</h6>
                           <p>Class information verification marks 2023 -24</p>
                        </div>
                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                   </div>
                   </li>
                   <li>
                   <div className="newsbox">
                       <div className="newsdesc">
                           <h6>Access Code for digi locker accounts of class X XIi</h6>
                           <p>Access Code for digi locker accounts of class X XIi</p>
                        </div>
                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                   </div>
                   </li>  */}
             </ul>
         </marquee>
     </div>
     </>
  )
}

export default News
