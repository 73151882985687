import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer' 
import { useEffect } from 'react'
const CbseResultAnalysis = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Cbse Result Analysis - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>   Academic </li>
                <li>Cbse Result Analysis</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Cbse Result Analysis</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                        <h5>Class XII</h5>
                      <div className="table-responsive maintable"> 
                      <table className="table">
                          <thead> 
                              <tr>
                                  <th>YEAR</th>
                                  <th>No of Students Appeared</th>
                                  <th>No of students passed</th>
                                  <th>Pass Percentage</th>
                                  <th>Highest score</th> 
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>2021-22</td>
                                  <td>193</td>
                                  <td>192</td>
                                  <td>99.48%</td>
                                  <td>99.4%</td> 
                              </tr>
                              <tr>
                                  <td>2020-21</td>
                                  <td>183</td>
                                  <td>183</td>
                                  <td>100%</td>
                                  <td>98.6%</td> 
                              </tr>
                              <tr>
                                  <td>2019-20</td>
                                  <td>175</td>
                                  <td>175</td>
                                  <td>100%</td>
                                  <td>97.8%</td> 
                              </tr>
                              <tr>
                                  <td>2018-19</td>
                                  <td>193</td>
                                  <td>192</td>
                                  <td>99.48%</td>
                                  <td>97.4%</td> 
                              </tr>
                              <tr>
                                  <td>2017-18</td>
                                  <td>179</td>
                                  <td>179</td>
                                  <td>100%</td>
                                  <td>96%</td> 
                              </tr>
                          </tbody>
                      </table>
                      </div>
                      <h5>Class X</h5>
                      <div className="table-responsive maintable">
       
                      <table className="table">
                          <thead> 
                              <tr> 
                                  <th>YEAR</th>
                                  <th>No of Students Appeared</th>
                                  <th>No of students cleared</th>
                                  <th>Pass Percentage</th>
                                  <th>Highest score</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr> 
                                  <td>2021-22</td>
                                  <td>210</td>
                                  <td>210</td>
                                  <td>100%</td>
                                  <td>98%</td>
                              </tr>
                              <tr> 
                                  <td>2020-21</td>
                                  <td>191</td>
                                  <td>191</td>
                                  <td>100%</td>
                                  <td>99.8%</td>
                              </tr>
                              <tr>
                                  
                                  <td>2019-20</td>
                                  <td>213</td>
                                  <td>213</td>
                                  <td>100%</td>
                                  <td>98%</td>
                              </tr>
                              <tr> 
                                  <td>2018-19</td>
                                  <td>214</td>
                                  <td>214</td>
                                  <td>100%</td>
                                  <td>98%</td>
                              </tr>
                              <tr> 
                                  <td>2017-18</td>
                                  <td>200</td>
                                  <td>198</td>
                                  <td>99%</td>
                                  <td>97.4%</td>
                              </tr>
                          </tbody>
                      </table>
                      </div>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default CbseResultAnalysis
