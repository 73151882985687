import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import LazyLoad from 'react-lazyload';
import { useEffect } from 'react'
const CBSEMandatory = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'CBSE Mandatory Disclosure - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School</li>
                <li>  CBSE Mandatory Disclosure</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> CBSE Mandatory Disclosure</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <div className="disclouser_list">
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/General-Information.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /> </LazyLoad>
                        <p> General Information</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Self-Decleration.pdf" target="_blank">
                           <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /></LazyLoad>
                            <p> Self Declaration</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Building-Safety-Certificate.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /> </LazyLoad>
                        <p> Building Safety Certificate</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Activity-Calendar.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /></LazyLoad>
                         <p> Activity Calendar</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Affiliation-Letter.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /></LazyLoad>
                         <p> Affiliation Letter</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Fire-Safety-Certificate.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /> </LazyLoad>
                        <p> Fire Safety Certificate</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/School-Recognition-Certificate.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /> </LazyLoad>
                        <p> School Recognition Certificate</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Society-Registration.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /> </LazyLoad>
                        <p> Society Registration</p></a>   
                        <a href="https://webapi.entab.info/api/image/PCSD/public/pdf/Water,-Health-and-Sanitation-Certificates.pdf" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/pdf_icon.png" alt="Affiliation Letter" /> </LazyLoad>
                        <p> Water, Health and Sanitation Certificates</p></a>   
                        <a href="https://youtu.be/Xu4gxovyDEk" target="_blank"> 
                        <LazyLoad><img src="https://webapi.entab.info/api/image/PCSD/public/Images/video_icon.png" alt="Affiliation Letter" /></LazyLoad>
                        <p> CBSE Inspection Video</p> </a>   
                       </div>
                      </div>  
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default CBSEMandatory
