import React, { useEffect } from "react";
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import LazyLoad from 'react-lazyload';
const InterSchoolCompetitions = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Inter School Competitions - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Activities </li>
                <li>Inter School Competitions</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Inter School Competitions</h1>
                    </div>
                </div>
                <div className="row">
                  <div className="col-lg-12"> 
                       <LazyLoad> <img alt="PCS Delhi" src="https://webapi.entab.info/api/image/PCSD/public/Images/Inter-School.png" className="img-fluid rightimg"/> </LazyLoad>
                        <p><span className="green">Nano Nagle Inter-school Debate:</span> An Inter-school debate in memory of the school foundress Nano Nagle, this debate is held in April every year. The topic of the debate is on socially relevant issues and is open to all public schools in Delhi. These socially relevant issues help create awareness amongst the present generation and can thus bring about a change in society. </p>
                        <p><span className="green">Ishani Makkar Inter-school Softball Tournament: </span>This inter-school softball tournament is held in memory of Ishani Makkar, a young girl who met with a fatal accident. Since 1996 this tournament has been held every year. It is recognised by and held under the aegis of The Softball Association of Delhi. Some of the best players in Delhi are seen in action in this tournament. </p>
                        <p><span className="green">Nano Nagle Inter-school Basketball Tournament: </span>This inter-school tournament is held in memory of our Foundress Nano Nagle. Many well-known schools of Delhi participate in this tournament. This tournament is played under the guidance of officials from the Basketball Association of Delhi. Coaching is held after school hours. </p>
                        <p><span className="green">PCS Eco club Inter-school Debate:</span>The school always felt the need to educate and create awareness about the deteriorating environment.In 1990 The Eco club of the school came into being. At the turn of the new millennium, the school was awarded Environmental Management System Certificate. We felt that we needed to reach out to many more children in other schools in and around the National Capital Region of Delhi. Thus, PCS Eco Club Inter-school debate was instituted in the year 2000. Since then, it has been holding this Inter-school competition. Eminent schools of NCR of Delhi participate in this competition. The purpose is to train the future denizens to make a judicious choice of a cleaner and greener world. The winner is awarded the Rolling Trophy. </p>
                        <p><span className="green">Swarang Inter-school Painting Competition:</span> "Swarang" is an Inter-school Painting Competition held each year. Started in the year 2000, it is a much awaited Painting competition among the leading schools of Delhi and has created a niche for itself as budding artists of Delhi Schools get to be recognized and appreciated for their work. </p>
                        <p><span className="green">Art helps children to be observant</span>, creative and it inculcates aesthetic values in them. Our School aspires for this innovative development of the students and that they continue to be as creative in their work as always. Through Swarang our school has created a platform for upcoming creative artists to showcase their work and create a base for their future careers.</p>
                    </div>
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default InterSchoolCompetitions
